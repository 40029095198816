header {
  height: fit-content;
  width: 100%;
  background-color: #d3d6cc;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#header-logo{
  width: 300px;
  height: auto;
  margin: 20px;
}

footer {
  background-color: #868c7a;
  height: 50px;
}

body {
  margin: 0;
  background-color: 	#fbf7f5;
  font-family: Arial, Helvetica, sans-serif;
}

.main-div {
  background-color: #d3d6cc;
  width: 60%;
  height: auto;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 50px;
}

.news-div {
  background-color: #d3d6cc;
  width: 60%;
  height: auto;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
  padding: 20px 50px;
}
#news-title {
  font-size: 28px;
  margin-bottom: 30px;
}

.education-div {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: #d3d6cc;
  width: auto;
  height: auto;
  min-height: fit-content;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  letter-spacing: 2px;
  font-size: 16px;
  color: #5f6357;
  line-height: 2;
}

#education-div-small {
  height: auto;
  min-height: 50px;
  border-radius: 10px;
  background-color: #d3d6cc;
  width: auto;
  margin-top: 10px;
}

#no-bullets-list {
  list-style: none;
  margin-left: -15px;
}

#education-under-title {
  margin-left: 20px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}

#education-under-title-top {
  margin-left: 0px;
  font-size: 23px;
  margin-bottom: 30px;
}

.education-list li {
  margin-bottom: 20px;
}

#right-div {
  margin-left: 35%;
}

.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #868c7a;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.link {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: rgb(111, 115, 105);
}

.link:hover {
  text-decoration: underline;
}

h1 {
  letter-spacing: 4px;
  font-weight: 400;
  color: rgb(82, 85, 77);
  font-family: "Roboto", "Arial";
}

#small-header {
  font-size: 18px;
  margin-top: 0px;
}

h2 {
  margin-left: 5px;
  letter-spacing: 5px;
  font-size: 30px;
  font-weight: 300;
  color: rgb(99, 99, 97);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.under-title {
  margin-left: 5px;
  font-size: 16px;
  color: rgb(115, 115, 113);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.news-date {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(115, 115, 113);
  margin-bottom: -10px;
}

.news-text {
  font-size: 17px;
  width: 90%;
  margin-left: 5px;
  margin-bottom: 14px;
  color: rgb(87, 87, 85);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 1.8;
}

.main-text {
  font-size: 17px;
  width: 90%;
  margin-left: 7px;
  color: rgb(62, 62, 60);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 2.2;
}

li {
  color: rgb(90, 95, 90);
}

form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea[name="message"] {
  width: 90%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 10px;
}

#envelope {
  width: 100px;
  height: auto;
}

label {
  font-size: 16px;
  color: rgb(47, 47, 46);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 10px;
}

textarea {
  min-height: 150px;
  width: 100%;
}

#line {
  height: 10px;
  width: 70%;
  margin-left: -10px;
  margin-top: 20px;
}

button[type="submit"] {
  background-color: #acb1a4;
  padding: 12px 20px;
  border: none;
  letter-spacing: 2px;
  font-size: 18px;
  color: rgb(51, 52, 51);
  border-radius: 4px;
  width: 200px;
  cursor: pointer;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 20px;
}

button[type="submit"]:hover {
  background-color: #a0a499;
}

.navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 35px;
  text-decoration: none;
  letter-spacing: 3px;
}

.navbar li a:hover {
  background-color: #5f6357;
}

.active {
  background-color: #5f6357;
}

.services-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.service {
  width: 30%;
  height: 200px;
  background-color: #d3d6cc;
  margin: 20px 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #5f6357;
  text-align: center;
  line-height: 2;
}

.back {
  cursor: pointer;
  width: 150px;
  height: 50px;
  text-align: center;
  margin-left: 8px;
  font-size: 14px;
  background-color: #c0c3b9;
  font-weight: bold;
  color: #555751;
  border: none;
  border-radius: 2px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.back:hover {
  background-color: #a4a69d;
}

#arrow {
  margin-right: 10px;
}

#service-icon {
  font-size: 50px;
  margin: 10px;
}
#hands,
#eating,
#heart {
  font-size: 50px;
  margin: 8px;
  color: #5f6357;
}
.service:hover {
  background-color: #c0c3b8;
}

.service-active {
  background-color: #c0c3b8;
}

.module {
  border: dotted 4px #a0a398;
  padding: 10px;
  margin: 10px;
}

.module-title {
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 8px;
  font-size: 18px;
  color: #5d5e5a;
}

.module-h2 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  color: #5d5e5a;
  margin-left: 0px;
}

.module-h3 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  color: #4f504d;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  h1 {
    font-size: 18px;
    letter-spacing: 2px;
  }
  h2 {
    font-size: 16px;
    margin: 5px;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .under-title {
    font-size: 15px;
  }
  header {
    height: fit-content;
  }
  #header-logo{
    width: 200px;
    height: auto;
    margin: 20px;
  }
  .navbar {
    font-size: 10px;
  }
  .navbar li a {
    padding: 14px 15px;
  }
  .main-text {
    font-size: 14px;
  }
  .main-div {
    width: 90%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    padding: 20px;
  }
  #line {
    width: 50%;
  }
}

@media screen and (max-width: 767px) and (min-width: 281px) {
  h1 {
    font-size: 18px;
    letter-spacing: 2px;
  }
  #small-header {
    font-size: 14px;
  }
  h2 {
    font-size: 16px;
    margin: 5px;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .under-title {
    font-size: 13px;
  }
  form {
    width: 90%;
  }
  header {
    height: fit-content;
  }
  #header-logo{
    width: 200px;
    height: auto;
    margin: 20px;
  }
  .navbar {
    font-size: 12px;
    flex-direction: column;
  }
  .navbar li a {
    padding: 14px 15px;
  }
  .service-list li {
    margin-left: -20px;
  }
  .main-div {
    width: 80%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    padding: 20px;
  }
  .main-text {
    font-size: 14px;
  }
  #line {
    margin-top: 0px;
    width: 90%;
  }
  .services-container {
    flex-direction: column;
  }
  .service {
    width: 80%;
    margin-left: auto;
    margin-left: auto;
  }
  .back {
    font-size: 12px;
    margin-left: 0px;
    height: 40px;
  }
  .module-title {
    font-size: 14px;
  }
  #eating,
  #hands,
  #heart {
    font-size: 30px;
  }
  .module-h2 {
    font-size: 14px;
  }
  .module-h3 {
    font-size: 14px;
    line-height: 1.5;
  }
  .module {
    margin: 0px;
  }
  #education-under-title-top {
    font-size: 18px;
  }
  #education-under-title {
    font-size: 18px;
  }
  #education-div-small {
    font-size: 14px;
    padding: 15px;
  }
  .education-div {
    padding: 0px;
    border-radius: 5px;
    font-size: 14px;
  }
  #education-div{
    padding: 10px;
    margin-left: 10px;
  }
  #right-div {
    margin-left: 0px;
  }
  .news-div {
    width: 80%;
    padding: 20px;
    font-size: 14px;
  }
  .education-list {
    margin-left: -20px;
  }
  #no-bullets-list {
    margin-left: -20px;
  }
}

@media screen and (max-width: 280px) {
  h1 {
    font-size: 10px;
  }
  #small-header {
    font-size: 8px;
  }
  h2 {
    font-size: 12px;
    padding: 10px;
    letter-spacing: normal;
  }
  .under-title {
    font-size: 10px;
    margin-left: 15px;
    margin-top: -10px;
  }
  #line {
    margin-left: 10px;
    height: 10px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  header {
    height: fit-content;
  }
  #header-logo{
    width: 200px;
    height: auto;
    margin: 20px;
  }
  .navbar {
    flex-direction: column;
    font-size: 10px;
  }
  .main-div {
    width: 85%;
    margin-top: 20px;
    padding: 5px;
  }
  .main-text {
    font-size: 12px;
    margin-left: 15px;
  }
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: centers;
    justify-content: center;
  }
  .service {
    width: 70%;
    height: 150px;
    margin-left: 15px;
  }
  .back {
    font-size: 10px;
    margin-left: 0px;
    height: 30px;
  }
  .module-title {
    font-size: 14px;
  }
  #eating,
  #hands,
  #heart {
    font-size: 30px;
  }
  .module-h2 {
    font-size: 14px;
    margin-left: -10px;
  }
  .module-h3 {
    font-size: 14px;
    line-height: 1.5;
  }
  .module {
    margin: 0px;
  }
  .service-list li {
    margin-left: -20px;
  }
}
